var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "color-container" }, [
      _c("div", { staticClass: "color" }),
      _c(
        "div",
        { staticClass: "group" },
        [
          _vm._l(_vm.groupList, function (item) {
            return _c("div", { key: item, staticClass: "group-item" }, [
              _c("span", { staticClass: "txt" }, [_vm._v("-" + _vm._s(item))]),
            ])
          }),
          _c("div", { staticClass: "base" }, [_vm._v("-0")]),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "remark" }, [_vm._v("卫星数量-颜色")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }