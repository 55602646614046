<template>
  <div>
    <div class="color-container">
      <div class="color"></div>
      <div class="group">
        <div class="group-item" v-for="item in groupList" :key="item">
          <span class="txt">-{{ item }}</span>
        </div>
        <div class="base">-0</div>
      </div>
    </div>
    <div class="remark">卫星数量-颜色</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groupList: [50, 40, 30, 20, 10]
    }
  },
  created() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.color {
  width: 40px;
  height: 500px;
  background: linear-gradient(#0f0, #f00);
}
.color-container {
  display: flex;
  margin-right: 20px;
  padding-left: 25px;
}

.group {
  position: relative;
}
.group-item {
  position: relative;
  height: 100px;
  padding-left: 3px;
}
.base {
  position: absolute;
  padding-left: 3px;
  bottom: -8px;
  font-weight: 600;
}
.txt {
  position: absolute;
  top: -9px;
  left: 3px;
  font-weight: 600;
}
.remark {
  margin-top: 5px;
}
</style>
